import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ResponsiveTitle from "../../../components/ResponsiveTitle";
import CartTable from "../CartTable";
import OrderForm from "./OrderForm";
import { selectTotal } from "../../../state/cart";
import Descriptions from "antd/lib/descriptions";
import { selectCurrentExchangeRates } from "../../../state/app";
// import { formatCurrency } from "../../../utils/helpers";

const { Item } = Descriptions;

export const Summary = () => {
  const rates = useSelector(selectCurrentExchangeRates);
  const { total, currency } = useSelector(selectTotal);
  const shippingFee = (12 * rates[currency]) / rates["SGD"];
  return (
    <Row>
      <Descriptions bordered style={{ width: "100%" }}>
        <Item
          span={4}
          label={
            <em>
              Please Note: We have a flat delivery fee of 12.00 SGD dollars
              across Singapore.
            </em>
          }
        />
        <Item span={3} label={<strong>Merchandise Subtotal</strong>}>
          {(total / 100).toFixed(2)}
        </Item>
        <Item span={3} label={<strong>Shipping</strong>}>
          {shippingFee.toFixed(2)}
        </Item>
        <Item span={3} label={<strong>Total</strong>}>
          {(total / 100 + shippingFee).toFixed(2)}
        </Item>
      </Descriptions>
    </Row>
  );
};

const Checkout = () => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.cart);

  return (
    <>
      <Row justify="center">
        <Col>
          <ResponsiveTitle>{t("checkout.review")}</ResponsiveTitle>
          <Space direction="vertical" style={{ width: "100%" }}>
            <CartTable data={data} />
            <Summary />
          </Space>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <ResponsiveTitle>{t("checkout.order")}</ResponsiveTitle>
            <OrderForm lineItems={data} />
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default Checkout;
