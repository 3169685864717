import { Card, Form as FormComponent } from "antd";
import Typography from "antd/lib/typography";
import { Field, Formik } from "formik";
import { navigate } from "gatsby";
import * as countries from "i18n-iso-countries";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import BlockButton from "../../../components/BlockButton";
import { selectTotal } from "../../../state/cart";
import * as API from "../../../utils/api";
import { selectCurrentExchangeRates } from "../../../state/app";
import { ValidationSchema, fields, initialValues } from "./formFields";

const { Text } = Typography;

const OrderForm = ({ lineItems }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (lineItems.length < 1) navigate("app/cart");

  const { total, currency } = useSelector(selectTotal);
  const rates = useSelector(selectCurrentExchangeRates);
  const shippingFee = (1200 * rates[currency]) / rates["SGD"];
  console.log(shippingFee + total);

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        const {
          address: {
            city,
            country,
            address_line_1,
            address_line_2,
            postcode,
            name,
            contact,
          },
          deliveryDate,
          deliveryHours,
          notes,
        } = values;
        const lineItemsData =
          lineItems.length > 0 &&
          lineItems.map(
            ({ price, images, platform, description, slug, ...lineItem }) => {
              return {
                unitPrice: Math.ceil(price), // @FIXME unitPrice must be an integer. some contentful items are integers and some have floating digits. This is probably related to Contentful.
                amount: Math.ceil(price * lineItem.quantity),
                sellerPlatform: platform || null,
                thumbnail: images ? images[0] : null,
                ...lineItem,
              };
            }
          );
        const orderData = {
          value: Math.ceil(total),
          amount: Math.ceil(total + shippingFee),
          currency: currency,
          shipping: {
            address: {
              city: city || null,
              country: countries.getAlpha2Code(country, language) || null,
              line1: address_line_1 || null,
              line2: address_line_2 || null,
              postcode: postcode || null,
            },
            name: name || null,
            phone: contact || null,
            preferredDeliveryDate: deliveryDate || null, // convert toISOString()
            preferredDeliveryHours: () => {
              console.log(deliveryHours);
              if (deliveryHours === `AM (10am - 2pm)`) return "AM";
              if (deliveryHours === `PM (2pm - 6pm)`) return "PM";
              return null;
            },
            notes: notes || null,
          },
          lineItems: lineItemsData,
        };
        const order = await API.wetMarketCheckout(orderData);
        setSubmitting(false);
        navigate("/app/wet-market/checkout/success", {
          state: {
            order: order,
            shouldResetCart: true,
          },
        });
      }}
    >
      {({ submitForm, values, errors, touched, isSubmitting }) => {
        return (
          <Card style={{ width: "100%", border: "1px solid #DDDDDD" }}>
            <FormComponent layout="vertical">
              {fields.map((field) => {
                const {
                  name,
                  label,
                  component,
                  required,
                  description,
                  link,
                  arr,
                } = field;
                const validation = () => {
                  if (errors[name] && touched[name]) return `warning`;
                  else if (!errors[name] && touched[name]) return `success`;
                  return null;
                };
                return (
                  <FormComponent.Item
                    label={<Text strong>{t(label)}</Text>}
                    hasFeedback
                    required={required}
                    validateStatus={validation()}
                    // help={errors[name] && touched[name] && errors[name]}
                    key={name}
                  >
                    <Field
                      name={name}
                      placeholder={t(label)}
                      description={t(description)}
                      link={link && link()}
                      as={component}
                      arr={arr}
                    />
                  </FormComponent.Item>
                );
              })}
              <BlockButton onClick={() => submitForm()} loading={isSubmitting}>
                {t("checkout.order")}
              </BlockButton>
            </FormComponent>
          </Card>
        );
      }}
    </Formik>
  );
};

export default OrderForm;
