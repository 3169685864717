import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Markdown from "markdown-to-jsx";

import ModalComponent from "../../../components/ModalComponent";
import { faqContent } from "../../../markdown/faq";
import {
  AddressPicker,
  CheckboxComponent,
  DatePickerComponent,
  DropdownComponent,
  TextAreaComponent,
} from "../../../components/formComponents";

const Translate = ({ children }) => {
  const { t } = useTranslation();
  return t(children);
};

export const ValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    name: Yup.string().required("Required"),
    contact: Yup.string().required("Required"),
    address_line_1: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
  }),
  terms: Yup.bool().oneOf([true], "Field must be checked"),
});

export const fields = [
  {
    name: "address",
    label: "address.address",
    required: true,
    component: AddressPicker,
  },
  {
    name: "deliveryDate",
    key: "deliveryDate",
    label: "address.preferredDeliveryDate",
    required: false,
    component: DatePickerComponent,
  },
  {
    name: "deliveryHours",
    key: "deliveryHours",
    label: "address.preferredDeliveryHours",
    required: false,
    arr: [`AM (10am - 2pm)`, `PM (2pm - 6pm)`],
    component: DropdownComponent,
  },
  {
    name: "notes",
    key: "notes",
    required: false,
    label: "address.notes",
    component: TextAreaComponent,
  },
  {
    name: "terms",
    key: "terms",
    required: true,
    label: "nav.terms",
    description: "checkout.deliveryTerms",
    link: () => (
      <Button
        onClick={() =>
          Modal.info({
            maskClosable: true,
            icon: null,
            okText: "Close",
            width: "80%",
            content: (
              <ModalComponent>
                <Markdown>{faqContent}</Markdown>
              </ModalComponent>
            ),
          })
        }
        type="link"
      >
        <Translate>nav.terms</Translate>
      </Button>
    ),
    component: CheckboxComponent,
  },
];

export const initialValues = {
  address: {},
  deliveryDate: new Date(),
  deliveryHours: null,
  notes: "",
  terms: false,
};
